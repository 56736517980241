<template>
  <div
    class="bp-cart-drawer"
    :class="{ 'bp-cart-drawer--active': active && isCartInit }"
  >
    <div class="bp-cart-drawer__backdrop" @click="handleBackdropClick" />
    <div class="bp-cart-drawer__wrapper text--clr-black">
      <div class="bp-cart-drawer__title">
        <p
          class="bp-cart-drawer__title-copy text--uppercase text-zd-cart-drawer-title"
        >
          {{
            `${getCartTotalQuantity} ${UI_ELEMENT(
              getCartTotalQuantity == 1
                ? "cart_title_quantity_singular"
                : "cart_title_quantity_plural"
            )}`
          }}
        </p>
        <Cross class="bp-cart-drawer__close-cross" @click="CLOSE_CART" />
      </div>
      <div
        class="bp-cart-drawer__content-scrollcontainer flex--col-start-center"
        ref="cartDrawerContent"
      >
        <div class="bp-cart-drawer__content-stretcher">
          <CartUpsell />
          <div class="bp-cart-drawer__line-items">
            <div class="text-body text--center">
              <span
                class="bp-cart-drawer__empty-text mt-32"
                v-if="getIsCartEmpty"
              >
                {{ UI_ELEMENT("cart_drawer_empty_cart") }}
              </span>
              <RichText
                :content="UI_TEXT_CONTENT('cart_drawer_empty_link')"
                class="bp-cart-drawer__empty-link mt-8"
                v-if="getIsCartEmpty"
              />
            </div>
            <CartEntry
              class="bp-cart-drawer__line-item-entry"
              v-for="lineItem in getAdjustedLineItems"
              :key="
                lineItem.product.displayTitle +
                  JSON.stringify(lineItem.customData)
              "
              :lineItem="lineItem"
            />
            <CartGwpEntry class="bp-cart-drawer__line-item-entry" />
          </div>
        </div>
        <ProductMiniCarousel
          class="bp-cart-drawer__missed-products"
          mini
          v-if="getMissedProducts.length > 0"
          :title="UI_ELEMENT('cart_drawer_upsell_title_refresh')"
          :products="getMissedProducts"
        />
      </div>
      <div class="bp-cart-drawer__total">
        <div class="bp-cart-drawer__total-row text-commerce-heading-3">
          <div>
            {{ UI_ELEMENT("cart_header_subtotal") }}
          </div>
          <div>
            <span
              class="bp-cart-drawer__total-amount"
              :class="{
                'bp-cart-drawer__total-amount--strikethrough text--w-medium': getHasPriceAdjustment
              }"
            >
              {{ getCartTotal }}
            </span>
            <span
              v-if="getHasPriceAdjustment"
              class="bp-cart-drawer__total-amount bp-cart-drawer__total-amount--adjusted text--clr-purple_dark"
              >{{ getCartAdjustedTotal }}</span
            >
          </div>
        </div>
        <span class="bp-cart-drawer__discounts text-body-small mr-2">
          <template v-if="getHasDiscountCode">
            <RichText
              baseClass="text-body-small"
              :content="
                UI_TEXT_CONTENT('cart_message_shipping_discount', [
                  { tag: 'DISCOUNT_CODE', value: queryParamsMap.discount }
                ])
              "
            />
          </template>
          <template v-else>
            {{ UI_ELEMENT("cart_message_shipping") }}
          </template>
        </span>

        <BasicButton
          class="bp-cart-drawer__total-checkout text-zd-button-xlarge"
          :class="{
            'bp-cart-drawer__total-checkout--disabled': getIsCartEmpty
          }"
          large
          @click="checkout"
          :disabled="getIsCartEmpty"
        >
          {{ UI_ELEMENT("cart_button_checkout") }}
        </BasicButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"

import CartEntry from "./CartEntry.vue"
import CartGwpEntry from "./CartGwpEntry.vue"
import CartUpsell from "./CartUpsell.vue"
import ProductMiniCarousel from "@/components/ProductMiniCarousel.vue"
import Cross from "@/assets/cross.svg"
import RichText from "@/components/RichText"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock"

export default {
  props: ["active"],

  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        slidesOffsetBefore: 24,
        slidesPerView: "auto",
        centeredSlides: false,
        freeMode: true,
        breakpoints: {
          768: {
            slidesOffsetBefore: 40
          }
        }
      }
    }
  },
  components: {
    RichText,
    ProductMiniCarousel,
    CartUpsell,
    CartEntry,
    CartGwpEntry,
    Cross
  },

  created() {
    this.uiElement_manifest = [
      "cart_title_quantity_singular",
      "cart_title_quantity_plural"
    ]
    this.initCart()
  },

  methods: {
    ...mapActions("cart", ["INIT_GWP_PROMO", "CLOSE_CART", "CHECKOUT"]),
    ...mapActions("queryParams", ["CLEAR_PARAMS"]),

    ...mapMutations("cart", ["SET_CART_INIT"]),

    handleBackdropClick() {
      this.CLOSE_CART()
    },
    checkout() {
      if (this.getIsCartEmpty) {
        return
      } else {
        this.CHECKOUT()
      }
    },

    async initCart() {
      this.debug.log("*** initCart")
      // Update active GWP promo
      this.INIT_GWP_PROMO()
      this.SET_CART_INIT(true)
    }
  },
  computed: {
    ...mapState("identity", ["buuid"]),
    ...mapState("locale", ["locale"]),
    ...mapState("cart", ["isCartInit", "cartId"]),
    ...mapState("queryParams", ["queryParamsMap"]),
    ...mapGetters("locale", ["formatPrice"]),
    ...mapGetters("cart", [
      "getIsCartActive",
      "getAdjustedLineItems",
      "getCartTotalCents",
      "getCartAdjustedTotalCents",
      "getCartTotalQuantity"
    ]),
    ...mapGetters("breakpoints", ["GET_IS_BELOW_BREAKPOINT"]),
    ...mapGetters("content", [
      "GET_SITE_CONFIG",
      "GET_PRODUCT_ENTRY",
      "GET_FILTER_ENTRIES"
    ]),
    ...mapGetters("queryParams", ["getHasDiscountCode"]),

    getIsCartEmpty() {
      return this.getAdjustedLineItems.length == 0
    },

    getHasPriceAdjustment() {
      return this.getCartTotalCents !== this.getCartAdjustedTotalCents
    },

    getCartTotal() {
      return this.formatPrice({ cents: this.getCartTotalCents })
    },

    getCartAdjustedTotal() {
      return this.formatPrice({ cents: this.getCartAdjustedTotalCents })
    },

    getMissedProducts() {
      if (this.GET_SITE_CONFIG.cartAddOnProductListCollection) {
        return this.GET_SITE_CONFIG.cartAddOnProductListCollection.items.map(
          product => this.GET_PRODUCT_ENTRY(product.sys.id, product.__typename)
        )
      }

      return []
    }
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.CLOSE_CART()
      }
    },
    getIsCartActive(newVal) {
      if (newVal) {
        this.initCart()
      }

      if (this.GET_IS_BELOW_BREAKPOINT("md")) {
        if (newVal) {
          disableBodyScroll(this.$refs["cartDrawerContent"])
        } else {
          enableBodyScroll(this.$refs["cartDrawerContent"])
          clearAllBodyScrollLocks()
        }
      } else if (!newVal) {
        enableBodyScroll(this.$refs["cartDrawerContent"])
        clearAllBodyScrollLocks()
      }
    }
  }
}
</script>

<style lang="scss">
.bp-cart-drawer {
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  right: 0;
  transition: opacity 0.3s ease;

  &--active {
    .bp-cart-drawer__wrapper {
      transform: translateX(0);
    }
  }

  z-index: 5;

  @include media-breakpoint-up(md) {
    position: absolute;
    height: 100vh;
    &--active {
      .bp-cart-drawer__backdrop {
        pointer-events: all;

        opacity: 0.2;
      }

      .bp-cart-drawer__wrapper {
        transform: translateX(-490px);
      }
    }
  }

  &__backdrop {
    background: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__wrapper {
    background: $bleach-white;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    transition: transform 0.3s ease, right 0.3s ease;
    transform: translateX(100%);

    display: flex;
    flex-direction: column;
    pointer-events: all;

    @include media-breakpoint-up(md) {
      right: -490px;
      width: 490px;
    }
  }

  &__divider {
    width: calc(100% - 70px);
    border-bottom: 1px solid #000000;
    height: 1px;
    margin: 14px auto 0;
  }

  &__title {
    display: flex;
    align-items: center;
    padding: 24px 0 16px;
  }

  &__title-copy {
    width: 100%;
    text-align: center;
  }

  &__discounts {
    color: $bleach-grey-40;
  }

  &__empty-text {
    margin-top: 30px;
  }

  &__empty-link {
    margin-top: 8px;
  }

  &__close-cross {
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: 24px;

    @include media-breakpoint-up(md) {
      right: 40px;
    }

    path {
      stroke: getcolour(bleach_black);
      vector-effect: non-scaling-stroke;
      stroke-width: 1.5px;
    }

    line {
      stroke-width: 1px;
    }
  }

  &__content-scrollcontainer {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    padding-bottom: 200px;
    flex: 1;

    // background-color: getcolour(bleach_grey_90);

    @include media-breakpoint-down(md) {
      min-height: 100%;
      max-height: 100vh;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 150px;
    }
  }

  &__content-stretcher {
    flex: 1 0 auto;
    width: 100%;
  }

  &__line-items {
    width: 100%;
    height: auto;
    padding: 32px 24px 16px;

    @include media-breakpoint-up(md) {
      padding: 32px 40px 16px;
    }
  }

  &__line-item-entry {
    border-bottom: 0.5px solid getcolour(bleach_grey_90);

    &:last-child {
      border-bottom: unset;
    }
  }

  &__missed-products {
    flex: 0 0 auto;
  }

  &__total {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background: $bleach-white;
    padding: 0 24px;
    z-index: 10;
    width: 100%;
    filter: drop-shadow(0 -10px 5px rgba(getcolour(bleach_black), 0.05));

    @include media-breakpoint-up(md) {
      padding: 0 40px;
      margin-right: 40px;
      width: 100%;
    }
  }

  &__total-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-top: 24px;

    // @include media-breakpoint-up(md) {
    //   margin-right: 35px;
    // }
  }

  &__total-amount {
    margin-left: 0.5em;
    &--strikethrough {
      position: relative;
      // text-decoration: line-through;
      color: getcolour(bleach_grey_60);
      &:after {
        content: "";
        position: absolute;
        width: 110%;
        height: 2px;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(-25deg);
        background-color: getcolour(bleach_grey_60);
      }
    }
  }

  &__total-checkout {
    &.bp-basic-button {
      width: calc(100% + 35px);
      background: #b5e850;
      color: black;
      margin: 24px auto 0;
      padding: 17px 0;
      position: relative;
      left: -24px;
      height: auto;
      width: calc(100% + 48px);

      @include media-breakpoint-up(md) {
        left: -40px;
        width: calc(100% + 80px);
      }

      .bp-basic-button__text {
        font-size: inherit;
        font-weight: inherit;
        color: black;
      }
    }

    &--disabled.bp-basic-button {
      background: #d8d8d8;

      .bp-basic-button__text {
        color: #9f9f9f;
      }
    }
  }
}
</style>
